@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&family=Space+Grotesk:wght@400;700&display=swap");

/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  padding: 10px 0;
}

header {
  background-color: #343434;
}

body {
  background-color: #1e1e1e;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-size: 1.1rem;
  line-height: 1.2em;
}

a {
  color: #5bcefa;
}

a:hover {
  color: #cccccc;
}

h1 {
  color: #000;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 2px;
  margin: 20px 0 0px 0;
  padding: 8px 0 15px 0;
  border-top: solid 3px #5bcefa;
}

h1 span {
  background-color: #5bcefa;
  padding: 10px;
}

main {
  background-color: #343434;
  max-width: 2000px;
  min-height: 60vh;
}

.mainContent {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}

/* header and navigation */
.menuToggle {
  display: none;
  cursor: pointer;
  font-size: 2rem;
  color: #1e1e1e;
  padding-right: 10px;
}
nav a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 0 30px 0 0;
  display: inline-block;
}

nav a:hover {
  text-decoration: underline;
  color: #ffffff;
}

nav {
  width: 100%;
  background-color: #5bcefa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.socials {
  background-color: #1e1e1e;
  height: 100%;
  padding: 0 20px;
}

.socials a {
  color: #ffffff;
  padding: 5px;
}

.mainLogo {
  width: 200px;
  padding: 20px;
}

.mainLogoContainer {
  display: flex;
  justify-content: center;
}

/* article page */
.articlePage {
  margin: 10px auto;
  width: 80%;
}

/* resource page */
.resourceColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 20px;
  column-gap: 25px;
}

/* homepage-specific items */
.homepageContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 25px;
}

.mainArticle {
  grid-column: 1 / 3;
}

.post,
.lastPost {
  padding: 25px 0 25px 0;
}

.post {
  border-bottom: 2px solid #5bcefa;
}

p.smallImg img {
  height: 150px;
  width: 90%;
}

.featuredArticle img,
.articlePage .mainImage {
  width: 90%;
  height: 350px;
  object-fit: cover;
}

.articlePage h2,
.featuredArticle h2 {
  line-height: 150%;
  font-family: "Space Grotesk", sans-serif;
  letter-spacing: 1;
  color: #5bcefa;
}

.postDetails {
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}

/* all items in footer */
footer {
  background-color: #1e1e1e;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  padding-top: 20px;
  margin-top: 30px;
}

.footerLogo {
  width: 100px;
}

.footerContainer {
  width: 60%;
  display: flex;
  flex-flow: column-wrap;
}

.footerLeft {
  padding: 20px;
  flex: 2 1 0;
}

.footerRight {
  padding: 20px;
}

.footerRight ul li {
  list-style-type: none;
  display: block;
  line-height: 2rem;
}

.footerRight ul li a {
  color: #5bcefa;
  text-decoration: none;
}

.footerRight ul li a:hover {
  text-decoration: underline;
  color: #ffffff;
}

.subscribe {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.contactForm {
  width: 50%;
  margin: 0 auto;
}

input,
textarea {
  padding: 5px;
  background-color: #1e1e1e;
  border: 2px solid #1e1e1e;
  color: #ffffff;
  border-radius: 3px;
  font-size: 1rem;
  margin: 5px;
  width: 100%;
}

label {
  width: 100%;
  margin: 5px;
}

textarea {
  font-family: "Lato", sans-serif;
  font-size: 1.1rem;
  line-height: 1.2em;
  height: 200px;
}

input:active,
input:focus {
  border: 2px solid #5bcefa;
}

.submit {
  padding: 10px;
  margin: 10px 5px 0 5px;
  width: 100%;
  background-color: #5bcefa;
  border: none;
  color: #1e1e1e;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1rem;
}

.submit:active {
  background-color: #1e1e1e;
  color: #5bcefa;
}

li {
  margin-left: 30px;
}

@media only screen and (max-width: 1000px) {
  .homepageContent {
    display: grid;
    grid-template-columns: 1fr;
  }

  .homepageContent div {
    grid-column: 1;
  }

  .footerContainer {
    width: 100%;
  }

  nav {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .navLinks {
    flex-direction: column;
    align-items: center;
    display: none;
    width: 100%;
  }

  .navLinks a {
    padding: 10px;
  }

  .navLinks.active {
    display: flex;
  }

  .menuToggle {
    display: block;
  }

  .articlePage {
    width: 100%;
  }

  .resourceColumns {
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 20px;
    column-gap: 25px;
  }

  .contactForm {
    width: 90%;
  }
}
